// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Container.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Container.tsx");
  import.meta.hot.lastModified = "1731498231000";
}
// REMIX HMR END

import { cn } from "../utils/styles/cn";
import { forwardRef } from "react";
const Container = forwardRef(_c = ({
  className,
  children,
  ...props
}, ref) => {
  return <main ref={ref} className={cn("size-full h-[100vh] bg-gray-200", "flex flex-col items-center md:justify-center")} {...props}>
        {children}
      </main>;
});
_c2 = Container;
Container.displayName = "Container";
export default Container;
var _c, _c2;
$RefreshReg$(_c, "Container$forwardRef");
$RefreshReg$(_c2, "Container");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;